class HeaderSlider {
  constructor(prevArw, nextArw) {
    this.settings = {
      slidesToShow: 1,
      dots: false,
      infinite: false,
      speed: 800,
      fade: true,
      prevArrow: prevArw,
      nextArrow: nextArw,
      autoplay: true,
    };
  }

  init() {
    $('.header-slider').slick(this.settings);
  }

  refresh() {
    if ($('.header-slider').length) {
      $('.header-slider').slick('refresh');
    }
  }
}

export default HeaderSlider;