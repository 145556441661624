class LogoSlider {
  constructor(prevArw, nextArw) {
    this.options = {
      lazyLoad: 'ondemand',
      slidesToShow: 7,
      slidesToScroll: 7,
      prevArrow: prevArw,
      nextArrow: nextArw,
      responsive: [{
          breakpoint: 1600,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 6,
          }
        },
        {
          breakpoint: 1350,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 556,
          settings: {
            arrows: true,
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            centerPadding: '80px',
          }
        }
      ]
    }
  }

  init() {
    $('.logo_slider_init').slick(this.options);
  }
  refresh() {
    $('.logo_slider_init').slick('refresh');
  }
}

export default LogoSlider;