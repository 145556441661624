class Carousel {
  constructor(prevArw, nextArw) {
    this.options = {
      slidesToShow: 4,
      dots: false,
      speed: 800,
      infinite: true,
      prevArrow: prevArw,
      nextArrow: nextArw,
      autoplay: false,
      centerPadding: '0px',
      responsive: [{
          breakpoint: 992,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    }
  }

  init() {
    $('.group_carousel_init').slick(this.options);
  }

  refresh() {
    $('.group_carousel_init').slick('refresh');
  }

}

export default Carousel;